<script setup lang="ts">
import { ref, reactive, onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia';
import axios from "axios";

import PlacesField from '@/vue/search/PlacesField.vue';
import TypesField from '@/vue/search/TypesField.vue';
import PriceField from '@/vue/search/PriceField.vue';
import RoomsField from '@/vue/search/RoomsField.vue';
import FiscalField from '@/vue/search/FiscalField.vue';
import AvancementField from '@/vue/search/AvancementField.vue';

import { useSearchFormStore } from '@/stores/searchFormStore';
const { formPayload } = storeToRefs(useSearchFormStore());

defineProps({
    formIsOpen: {
        type: Boolean,
        default: false
    }
})

const emits = defineEmits(['handleDisplaySearchForm'])

const {
    resetFormPayload
 } = useSearchFormStore();

const query = reactive({
    types: [] as ProgramType[],
    rooms: [] as ProgramRoom[],
    price: '' as ProgramPrice,
    dispositifs: [] as ProgramDispositif[],
    status: [] as ProgramStatus[],
    department: '' as string,
    city: '' as string,
    places: [] as ProgramPlace[],
});


const allFiltersShown = ref<Boolean>(false)
const programsCount = ref<Number>(0);

const formatUrlParameters = () => {
    const params = new URLSearchParams();

    (Object.keys(query) as Array<keyof typeof query>).forEach(key => {
        const value = query[key];
        if (Array.isArray(value)) {
            value.forEach((item, index) => {
                if (key === 'places') {
                    params.append(`${String(key)}[${index}]`, String(item?.value));
                } else {
                    params.append(`${String(key)}[${index}]`, String(item));
                }
            });
        }
    });

    return params.toString();
}

const loadPrograms = async () => {
    const response = await getProgramsCount();
    programsCount.value = response.programs_count;
}

const getProgramsCount = async () => {
    const response = await axios
        .get(`${import.meta.env.VITE_APP_URL}/wp-admin/admin-ajax.php?action=search_programs_count&${formatUrlParameters()}`);

    return response.data;
};

const getPrograms = async () => {
    window.location.href = `/immobilier-neuf/?${formatUrlParameters()}`;
};

// METHODS
const handleSubmitSearch = () => {
    console.log('submit form', formPayload.value)
}

const displayMoreFilters = () => {
    allFiltersShown.value = !allFiltersShown.value
}

const resetFormFilters = () => {
    resetFormPayload()
}

watch(query, () => {
    getProgramsCount().then((response) => {
        programsCount.value = response.programs_count;
    });
}, { deep: true });

onMounted(() => {
    loadPrograms();
})
</script>

<template>
    <div
        v-show="formIsOpen"
        id="homepageSearchFormMobile"
        @submit.prevent="handleSubmitSearch"
        class="fixed inset-0 bg-white z-[999] p-4 overflow-y-scroll"
    >
        <button
            @click="emits('handleDisplaySearchForm')"
            class="absolute top-4 right-4"
            type="button"
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                <path d="M25.8112 9.20532L9.00787 26.0086" stroke="#640A32" stroke-width="2"/>
                <path d="M9.00848 9.20532L25.8118 26.0086" stroke="#640A32" stroke-width="2"/>
            </svg>
        </button>
        <img src="/assets/images/logo_lnc.png" alt="LNC" class="scale-50">

        <h3 class="text-primary uppercase text-3xl text-center mt-2">
            Recherchez <br />votre logement
        </h3>

        <form @submit.prevent="getPrograms" class="flex flex-col w-full items-center gap-4 p-6">
            <PlacesField
                class="w-full"
                v-model="query.places"
            />
            <TypesField
                class="w-full"
                v-model="query.types"
                />
            <PriceField
                class="w-full"
                v-model="query.price"
            />

            <div v-if="allFiltersShown" class="flex flex-col gap-4 w-full">
                <RoomsField
                    class="w-full"
                    v-model="query.rooms"
                />

                <FiscalField
                    class="w-full"
                    v-model="query.dispositifs"
                />

                <AvancementField
                    class="w-full"
                    v-model="query.status"
                />
            </div>


            <button
                @click="displayMoreFilters"
                type="button"
                class="w-fit p-2 bg-red text-white font-regular text-base uppercase leading-none rounded-md inline-flex items-center gap-2"
            >
                <span class="btnContent">
                    {{ allFiltersShown ? 'Moins' : 'Plus' }} de filtres
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                </svg>
            </button>

            <div class="flex">
                <button
                    type="submit"
                    class="p-5 bg-primary text-white font-extrabold text-base uppercase leading-none rounded-sm inline-flex items-center gap-2"
                    @submit.prevent="getPrograms"
                >
                    <span class="whitespace-nowrap">
                        Voir les {{ programsCount ?? 0 }} résultat{{ programsCount > 1 ? 's' : '' }}
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
                </button>
            </div>
        </form>
    </div>
</template>
