import axios from "axios"

document.addEventListener('DOMContentLoaded', () => {
    const immodvisorsContainer = document.querySelector('.immodvisors-container');
    if(immodvisorsContainer) {
        console.log('block is here');
        getImmodvisorsAvis()
    }
})

const getImmodvisorsAvis = async () => {
    const response = await axios.get('http://api.immodvisor.com/les-nouveaux-constructeurs-boulogne-billancourt-92100-29567/express', {
        headers: {
            'Content-Type': 'application/json'
        },
        params: {
            key: "MIIY6H-4ACO3-CT2U-1DLLK-SRPU"
        }
    })
    console.log('response', response);
}