export class OffersSlider {
    private sliderContainer: HTMLElement;
    private sliderItems!: Array<HTMLElement>;
    private bulletPoints!: NodeListOf<HTMLElement> | undefined;
    private previousIndex!: number;
    private currentIndex!: number;
    private sliderTextContent: NodeListOf<HTMLElement> | undefined;
    private startX!: number | undefined;
    private startY!: number | undefined;
    private isMoving: boolean = false;
    private prevBtn: HTMLElement | null | undefined;
    private nextBtn: HTMLElement | null | undefined;
    private animationDuration: number = 500;

    constructor(private sliderId: String) {
        this.sliderContainer = document.querySelector(`[offers-slider-id="${this.sliderId}"]`)!;

        if (!this.sliderContainer) return;

        this.prevBtn = this.sliderContainer.nextElementSibling?.querySelector('.programContactSliderPrev');
        this.nextBtn = this.sliderContainer.nextElementSibling?.querySelector('.programContactSliderNext');

        this.sliderContainer.setAttribute('tabindex', '0');

        // Slider images
        this.sliderItems = Array.from(this.sliderContainer.querySelectorAll('.slider-item'));
        this.bulletPoints = this.sliderContainer.nextElementSibling?.querySelectorAll('.bullet-point');
        
        // Homepage slider text content
        this.sliderTextContent = document.querySelectorAll('[data-slide-content-id]');

        this.currentIndex = 0;

        this.setupEventListeners();
    }

    private setupEventListeners(): void {

        if (this.prevBtn) {
            this.prevBtn.addEventListener('click', () => this.changeSlide(-1));
        }

        if (this.nextBtn) {
            this.nextBtn.addEventListener('click', () => this.changeSlide(1));
        }

        // // Prevent manyally scrolling on touch devices
        this.sliderContainer?.addEventListener("touchstart", (event: TouchEvent) => {
            this.startX = event.touches[0].clientX;
            this.startY = event.touches[0].clientY;
        });
        
        this.sliderContainer?.addEventListener("touchmove", (event: TouchEvent) => {
            const currentX = event.touches[0].clientX;
            const currentY = event.touches[0].clientY;
    
            if(!this.startX || !this.startY) return;

            const deltaX = this.startX - currentX;
            const deltaY = this.startY - currentY;
    
            // Check if the swipe is horizontal (ignoring vertical movement)
            if (Math.abs(deltaX) > Math.abs(deltaY)) {
                if(this.isMoving) return;

                this.handleSwipe(deltaX);
                this.isMoving = true;
            }
        });

        // // Handle accessibility with arrow keys
        // this.sliderContainer.addEventListener("keydown", (event: KeyboardEvent) => {
        //     event.preventDefault()
        //     // TODO => HANDLE THIS LATER
        //     // console.log('event', event.key)
        //     // if(event.key === 'ArrowLeft') {
        //     //     this.changeSlide(-1);
        //     // } else if (event.key === 'ArrowRight') {
        //     //     this.changeSlide(1);
        //     // }
        //     // return
        // })

        this.showItem(0); // Show the first slide initially
    }


    private disableButtons(): void {
        this.prevBtn?.setAttribute('disabled', 'true');
        this.nextBtn?.setAttribute('disabled', 'true');
        this.prevBtn?.classList.add('opacity-50');
        this.nextBtn?.classList.add('opacity-50');
    }

    private enableButtons(): void {
        this.prevBtn?.removeAttribute('disabled');
        this.nextBtn?.removeAttribute('disabled');
        this.prevBtn?.classList.remove('opacity-50');
        this.nextBtn?.classList.remove('opacity-50');
    }

    private handleSwipe = (deltaX: number) => {
        setTimeout(() => {
            this.changeSlide(deltaX < 0 ? -1 : 1);
            this.isMoving = false;
        }, this.animationDuration);
    }

    private changeSlide(direction: number): void {
        this.previousIndex = this.currentIndex;
        this.currentIndex += direction;

        if (this.currentIndex < 0) this.currentIndex = this.sliderItems.length - 1;
        if (this.currentIndex >= this.sliderItems.length) this.currentIndex = 0;

        this.showItem(this.currentIndex);

        this.disableButtons();
        setTimeout(() => {
            this.enableButtons();
        }, this.animationDuration);
        this.sliderTextContent?.forEach((el, idx) => {
            if (idx === this.currentIndex) {
                el.classList.remove('translate-y-full');
                el.classList.remove('opacity-0');
            } else {
                el.classList.add('translate-y-full');
                el.classList.add('opacity-0');
            }
        })
    }

    private showItem(index: number): void {
        this.bulletPoints?.forEach((point, idx) => {
            // Animate previous element
            if( idx === this.currentIndex - 1 ) {
                setTimeout(() => {
                    point.classList?.add('w-12');
                    point.classList?.remove('w-12');
                    point.classList?.add('w-2');
                }, this.animationDuration);
            }

            if (idx === this.currentIndex) {
                point.classList?.remove('opacity-50');
                point.classList?.remove('w-2');
                point.classList?.add('w-12');

            } else {
                point.classList?.remove('w-12');
                point.classList?.add('w-2');
                point.classList?.add('opacity-50');
            }
        });

        // Scroll horizontally within the slider container
        const targetElement = this.sliderItems[index];

        const rect = targetElement?.getBoundingClientRect();
        let leftPosition
        const beforeElement = this.sliderItems[index - 1];

        // Reset button styles
        this.prevBtn?.classList.remove('opacity-50');
        this.prevBtn?.removeAttribute('disabled');

        this.nextBtn?.classList.remove('opacity-50');
        this.nextBtn?.removeAttribute('disabled');

        if(index === 0) {
            leftPosition = 0
            this.prevBtn?.classList.add('opacity-50');
            this.prevBtn?.setAttribute('disabled', 'true');
        } else if(index === this.sliderItems.length) {
            this.nextBtn?.classList.add('opacity-50');
            this.nextBtn?.setAttribute('disabled', 'true');
        } else if (beforeElement) {
            const beforeRect = beforeElement.getBoundingClientRect();
            if(this.previousIndex > this.currentIndex) {
                leftPosition = this.sliderContainer.scrollLeft - (rect.left - beforeRect.left)
            } else {
                leftPosition = this.sliderContainer.scrollLeft + (rect.left - beforeRect.left)
            }
        }

        console.log('leftPosition :', leftPosition)

        // Ensure the slider container allows horizontal scrolling
        this.sliderContainer.style.overflowX = 'auto';

        // Smoothly scroll to the calculated position
        this.sliderContainer.scrollTo({
            left: leftPosition,
            behavior: 'smooth'
        });
    }

    public static initSliders(): void {
        document.addEventListener('DOMContentLoaded', () => {
            document.querySelectorAll('[offers-slider-id]').forEach(el => {
                const sliderId = el.getAttribute('offers-slider-id');
                if (sliderId) {
                    new OffersSlider(sliderId);
                }
            });
        });
    }
}

OffersSlider.initSliders();
