<script setup lang="ts">
import { reactive } from 'vue';
import SimpleInput from '@/vue/inputs/SimpleInput.vue';

const filleuls = reactive([
    {
        lastname: '',
        firstname: '',
        phone_number: '',
        email: '',
        address: '',
        city: '',
        zip_code: '',
        date: '',
    },
]);

const addFilleul = () => {
    if (filleuls.length >= 3) return;
    filleuls.push({
        lastname: '',
        firstname: '',
        phone_number: '',
        email: '',
        address: '',
        city: '',
        zip_code: '',
        date: '',
    });
};

const removeFilleul = () => {
    filleuls.pop();
};
</script>

<template>
    <div class="py-14 md:px-20">
        <div class="p-8 md:p-16 space-y-6 md:space-y-8 bg-light rounded">
            <h2 class="text-primary text-4xl font-regular uppercase text-center">
                Vos coordonnées
            </h2>

            <div class="grid md:grid-cols-2 gap-6 md:gap-8">
                <SimpleInput placeholder="Nom*" name="lastname" />
                <SimpleInput placeholder="Prénom*" name="firstname" />
                <SimpleInput placeholder="Téléphone*" name="phone_number" type="tel" />
                <SimpleInput placeholder="Email*" name="email" type="email" />
                <SimpleInput placeholder="Adresse*" name="address" />
                <SimpleInput placeholder="Ville*" name="city" />
                <SimpleInput placeholder="Code postal*" name="zip_code" />
                <SimpleInput placeholder="Date de réservation" name="date" type="date" />
            </div>
        </div>
        <div class="p-8 md:p-16 space-y-6 md:space-y-8 bg-primary rounded">
            <h2 class="text-white text-4xl font-regular uppercase text-center">
                Coordonnées de votre filleul
            </h2>

            <div
                v-for="(filleul, index) in filleuls"
                :key="index"
                class="grid md:grid-cols-2 gap-6 md:gap-8"
            >
                <SimpleInput
                    theme="dark"
                    v-model="filleul.lastname"
                    placeholder="Nom*"
                    :name="`lastname-${index}`"
                />
                <SimpleInput
                    theme="dark"
                    v-model="filleul.firstname"
                    placeholder="Prénom*"
                    :name="`firstname-${index}`"
                />
                <SimpleInput
                    theme="dark"
                    type="tel"
                    v-model="filleul.phone_number"
                    placeholder="Téléphone*"
                    :name="`phone_number-${index}`"
                />
                <SimpleInput
                    theme="dark"
                    type="email"
                    v-model="filleul.email"
                    placeholder="Email*"
                    :name="`email-${index}`"
                />
                <SimpleInput
                    theme="dark"
                    v-model="filleul.address"
                    placeholder="Adresse*"
                    :name="`address-${index}`"
                />
                <SimpleInput
                    theme="dark"
                    v-model="filleul.city"
                    placeholder="Ville*"
                    :name="`city-${index}`"
                />
                <SimpleInput
                    theme="dark"
                    v-model="filleul.zip_code"
                    placeholder="Code postal*"
                    :name="`zip_code-${index}`"
                />
                <SimpleInput
                    theme="dark"
                    type="date"
                    v-model="filleul.date"
                    placeholder="Date de réservation"
                    :name="`date-${index}`"
                />

                <div class="col-span-full flex items-center justify-center gap-6">
                    <button
                        v-if="filleuls.length > 1"
                        class="inline-flex items-center gap-1 p-1 pl-2 text-secondary uppercase bg-white rounded"
                        @click="removeFilleul"
                    >
                        Retirer un filleul
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 -rotate-90">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                        </svg>
                    </button>
                    <button
                        v-if="filleuls.length-1 == index && filleuls.length < 3"
                        class="inline-flex items-center gap-1 p-1 pl-2 text-secondary uppercase bg-white rounded"
                        @click="addFilleul"
                    >
                        Ajouter un filleul
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 rotate-90">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div class="p-10 flex flex-col items-center gap-6">
            <p class="text-primary text-center">
                *Champs obligatoire
            </p>
            <label class="text-center">
                <input type="checkbox">
                J'accepte que mes données soient utilisées par Les Nouveaux Constructeurs et CFH
                <br>à des fins de prospection commerciale et pour recevoir des offres et promotions
            </label>
            <button class="p-4 pr-3 rounded text-white bg-primary uppercase font-bold inline-flex items-center gap-1">
                Envoyer
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                </svg>
            </button>
            <p class="text-xs text-justify">
                Vos données à caractère personnel et celles de vos filleuls seront utilisées par Les Nouveaux Constructeurs et CFH dans le cadre des opérations de parrainage, c’est-à-dire pour contacter vos filleuls au moyen des coordonnées que vous avez renseigné et pour vous accorder l’allocation prévue en cas de réservation confirmée par l’un de vos filleuls. En fonction de vos choix, elles peuvent aussi être utilisées pour vous adresser des messages de prospection commerciale. Vous disposez d'un droit d'accès, de rectification, de portabilité, d’effacement de vos données à caractère personnel, d’un droit à la limitation du traitement, ainsi que d’un droit d’opposition au traitement de vos données. Vous disposez également du droit de nous faire parvenir des directives spéciales relatives au sort de vos données à caractère personnel en cas de décès, et d’introduire une réclamation auprès de l’autorité de contrôle compétente. Pour en savoir plus sur la gestion de vos données personnelles, vos droits et comment exercer vos droits, vous pouvez consulter notre Politique de protection des Données Personnelles en cliquant sur le lien suivant : https://www.lesnouveauxconstructeurs.fr/politiques-de-protection-des-donnees-et-politique-de-cookies/
            </p>
        </div>
    </div>
</template>
