<script setup lang="ts">
import { onMounted, reactive, ref, watch } from 'vue';
import { useBrowserLocation } from '@vueuse/core'
import axios from "axios";
import ResultCard from '@/vue/program/ResultCard.vue';
import ResultSkeletonCard from '@/vue/program/ResultSkeletonCard.vue';
import SearchMap from '@/vue/SearchMap.vue';
import PlacesField from '@/vue/search/PlacesField.vue';
import TypesField from '@/vue/search/TypesField.vue';
import PriceField from '@/vue/search/PriceField.vue';
import RoomsField from '@/vue/search/RoomsField.vue';
import FiscalField from '@/vue/search/FiscalField.vue';
import AvancementField from '@/vue/search/AvancementField.vue';
import searchFormHomeMobile from '@/vue/forms/SearchFormHomeMobile.vue';

const query = reactive({
    types: [] as ProgramType[],
    rooms: [] as ProgramRoom[],
    price: '' as ProgramPrice,
    dispositifs: [] as ProgramDispositif[],
    status: [] as ProgramStatus[],
    department: '' as string,
    city: '' as string,
    places: [] as ProgramPlace[],
    latitude: 48.8566,
    longitude: 2.3522,
    distance: 50,

    page: 1 as number,
    perPage: 15 as number,
});

const showMoreFields = ref(false);
const loading = ref(false);
const programs = ref([] as Program[]);
const location = useBrowserLocation()
const programsCount = ref(0);
const pagination = ref({
    currentPage: 1,
    maxPage: 1,
    totalCount: 0,
});

const displayPlacesField = ref(true);
const formIsOpen = ref(false);
const displayMap = ref(false);
const displayedComponent = ref<string>("all");

const handleDisplaySearchForm = () => {
    formIsOpen.value = !formIsOpen.value;
}

const updateDistance = (event: Event) => {
    const target = event.target as HTMLInputElement;
    query.distance = parseInt(target.value);
    loadPrograms();
};

const formatUrlParameters = () => {
    const params = new URLSearchParams();

    (Object.keys(query) as Array<keyof typeof query>)?.forEach(key => {
        const value = query[key];
        if (Array.isArray(value)) {
            value?.forEach((item, index) => {
                if (key === 'places') {
                    params.append(`${String(key)}[${index}]`, String(item?.value));
                } else {
                    params.append(`${String(key)}[${index}]`, String(item));
                }
            });
        } else if (value) {
            if ((key === 'latitude' || key === 'longitude')
            && (query.places.length > 0 || query.department || query.city)
            ) {
                return;
            }

            params.append(String(key), String(value));
        }
    });

    return params.toString();
};

const getProgramsCount = async () => {
    const response = await axios
        .get(`${import.meta.env.VITE_APP_URL}/wp-admin/admin-ajax.php?action=search_programs_count&${formatUrlParameters()}`);

    return response.data;
};

const getPrograms = async () => {
    loading.value = true;

    const response = await axios
        .get(`${import.meta.env.VITE_APP_URL}/wp-admin/admin-ajax.php?action=search_programs&${formatUrlParameters()}`);

    loading.value = false;

    return {
        programs: response.data.programs,
        pagination: response.data.pagination,
    }
};

const loadPrograms = async () => {
    const response = await getPrograms();
    console.log('response', response)
    programs.value = response?.programs;
    pagination.value = response?.pagination;
    programsCount.value = pagination.value?.totalCount;
}

const loadMorePrograms = async () => {
    if (pagination.value.currentPage >= pagination.value.maxPage) {
        return;
    }

    query.page += 1;

    const { programs: newPrograms, pagination: newPagination } = await getPrograms();
    programs.value = [...programs.value, ...newPrograms];
    pagination.value = newPagination;
};

onMounted(() => {
    // Check params on mounted and add them to query (when coming from home)
    const urlParams = Object.fromEntries(new URLSearchParams(location.value.search));
    if (Object.keys(urlParams).length > 0) {
        Object.assign(query, urlParams);
    }

    // if department or city is set on url, hide places field
    const dataset = document.getElementById('search-results')?.dataset;
    if (dataset?.department) {
        query.department = dataset.department;
        displayPlacesField.value = false;
    }
    if (dataset?.city) {
        query.city = dataset.city;
        displayPlacesField.value = false;
    }

    // get user location
    navigator.geolocation.getCurrentPosition((position) => {
        query.latitude = position.coords.latitude;
        query.longitude = position.coords.longitude;

        loadPrograms();
    }, () => {
        loadPrograms();
    });
});

const resetFilters = () => {
    query.types = [];
    query.rooms = [];
    query.price = '';
    query.dispositifs = [];
    query.status = [];
    query.department = '';
    query.city = '';
    query.places = [];
    loadPrograms();
};

const handleDisplayMobileView = () => {
    displayMap.value = !displayMap.value;
}

const handleDisplayComponents = (component: string) => {
    // if (value === 'map') {
    //     displayedComponent.value = value;
    // } else if(value ==='list') {
    //     displayedComponent.value = false;
    // } else {
        
    // }
    displayedComponent.value = component;
    console.log('displaying', displayedComponent.value)
}

watch(query, () => {
    getProgramsCount().then((response) => {
        programsCount.value = response.programs_count;
    });
}, { deep: true });
</script>

<template>
    <div class="">
        <!-- search -->
        <div class="bg-white p-4 lg:p-6">
            <div class="max-md:hidden xl:max-w-screen-xl 2xl:max-w-screen-2xl w-full mx-auto flex items-start gap-4">
                <div class="w-full relative z-50 space-y-2">
                    <div class="w-full grid grid-cols-3 gap-x-4 gap-y-2">
                        <PlacesField
                            v-if="displayPlacesField"
                            v-model="query.places"
                        />
                        <TypesField
                            v-model="query.types"
                        />
                        <PriceField
                            v-model="query.price"
                        />
                        <RoomsField
                            v-model="query.rooms"
                            v-show="showMoreFields"
                        />
                        <FiscalField
                            v-model="query.dispositifs"
                            v-show="showMoreFields"
                        />
                        <AvancementField
                            v-model="query.status"
                            v-show="showMoreFields"
                        />
                    </div>
                    <div class="flex items-center gap-6">
                        <button class="inline-flex items-center gap-1 p-1 pl-2 bg-secondary uppercase text-white rounded" @click="showMoreFields = !showMoreFields">
                            {{ showMoreFields ? 'Moins' : 'Plus' }} de filtres
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                            </svg>
                        </button>
                        <button class="text-primary flex gap-2 items-center" @click="resetFilters">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                <path d="M11.9969 0C12.393 0.1353 12.6497 0.394386 12.6746 0.856062C12.6977 1.28607 12.7185 1.71608 12.7393 2.14645C12.7693 2.77366 12.7976 3.40122 12.8279 4.02842C12.8521 4.53184 12.5587 4.92587 12.0835 4.96365C11.3152 5.02446 10.5449 5.05541 9.77529 5.09823C9.51488 5.11262 9.25481 5.1299 8.99406 5.13709C8.51278 5.15005 8.14023 4.79129 8.12421 4.30694C8.10819 3.83555 8.45518 3.44764 8.92657 3.41705C9.23743 3.3969 9.5493 3.38719 9.8605 3.37063C9.97128 3.36488 10.0821 3.35336 10.2348 3.34113C9.81414 3.02878 9.3966 2.81144 8.95077 2.65419C5.88006 1.57215 2.67334 3.64447 2.17741 7.02986C1.74589 9.97552 3.7494 12.8147 6.54539 13.2195C8.79057 13.5448 10.8827 12.3937 11.9168 10.2645C12.1135 9.85965 12.4322 9.65454 12.7809 9.70888C13.359 9.79848 13.6654 10.4055 13.4081 10.9651C13.0461 11.7535 12.5638 12.4523 11.955 13.0478C10.5763 14.3965 8.94157 15.05 7.06417 14.9975C3.88302 14.9086 1.12793 12.3785 0.600295 9.07807C0.00721624 5.36631 2.09492 1.87621 5.52694 0.894925C7.41524 0.354803 9.20743 0.654191 10.8827 1.72328C10.9393 1.75962 10.9959 1.79597 11.0678 1.84239C11.0678 1.68334 11.0702 1.54336 11.0671 1.40374C11.0651 1.30802 11.0535 1.21266 11.0494 1.11731C11.0225 0.462396 11.1615 0.232457 11.7249 0H11.9969V0Z" fill="#640A32"/>
                            </svg>
                            <p class="border-b border-primary">
                                Réinitialiser la recherche
                            </p>
                        </button>
                    </div>
                </div>

                <div class="flex">
                    <button
                        type="button"
                        class="p-5 bg-primary text-white font-extrabold text-base uppercase leading-none rounded-sm inline-flex items-center gap-2"
                        @click="loadPrograms"
                    >
                        <span class="whitespace-nowrap">
                            Voir les {{ programsCount ?? 0 }} résultat{{ programsCount > 1 ? 's' : '' }}
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="md:hidden flex justify-between items-center">
                <button
                    @click="handleDisplaySearchForm"
                    class="px-2 lg:px-3 py-2 inline-flex items-center gap-2 text-sm text-white bg-red uppercase rounded"
                >
                    Modifier ma recherche
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
                </button>
                <div class="flex gap-2 lg:gap-4">
                    <p class="uppercase lg:text-lg"
                    :class="displayMap && 'font-bold'"
                    >carte</p>
                    <div class="flex h-6 w-12 bg-gray-200 rounded-full transition transform duration-600" @click="handleDisplayMobileView">
                        <span
                            class="h-6 z-100 rounded-full w-6 bg-primary"
                            :class="displayMap ? 'translate-x-0' : 'translate-x-6'"
                        ></span>
                    </div>
                    <p class="uppercase lg:text-lg"
                    :class="!displayMap && 'font-bold'"
                    >liste</p>
                </div>

                <searchFormHomeMobile
                    :formIsOpen="formIsOpen"
                    @handleDisplaySearchForm="handleDisplaySearchForm"
                />
            </div>
        </div>

        <div class="xl:max-w-screen-xl 2xl:max-w-screen-2xl w-full mx-auto">
            <div class="grid"
            :class="displayedComponent === 'all' ? 'lg:grid-cols-2' : 'grid-cols-1'">
                <!-- col-1 -->
                <div
                    class="p-6 flex flex-col gap-5"
                    :class="displayMap && 'max-lg:hidden'"
                >
                    <div class="lg:flex lg:items-baseline lg:gap-2">
                        <h1 class="font-regular text-[2rem] md:text-[2.375rem] text-secondary text-nowrap">
                            {{ pagination?.totalCount ?? 0 }} résidence{{ pagination?.totalCount > 1 ? 's' : '' }}
                        </h1>
                        <p class="font-middle text-base leading-tight text-nowrap">
                            {{ pagination?.totalCount > 1 ? 'correspondent' : 'correspond' }} à votre recherche
                        </p>
                        <div class="flex">
                            <button
                                @click="handleDisplayComponents('map')"
                                :class="displayedComponent === 'map' && 'text-red font-semibold border-b border-red'"
                            >
                                Carte
                            </button>
                            |
                            <button 
                                @click="handleDisplayComponents('all')"
                                :class="displayedComponent === 'all' && 'text-red font-semibold border-b border-red'"
                            >
                                Mixte
                            </button>
                            |
                            <button
                                @click="handleDisplayComponents('list')"
                                :class="displayedComponent === 'list' && 'text-red font-semibold border-b border-red'"
                            >
                                Liste
                            </button>
                        </div>
                    </div>

                    <div v-if="displayPlacesField" class="rounded-md bg-white px-6 py-4">
                        <p class="mb-2">
                            Rechercher les résidences à proximité dans un rayon de <strong>{{ query.distance }} km</strong>
                        </p>
                        <input
                            class="w-full slider-range"
                            type="range"
                            step="25"
                            min="25"
                            max="150"
                            :value="query.distance"
                            @input="updateDistance"
                            @change="loadPrograms"
                        >
                        <div class="flex items-center justify-between">
                            <div class="text-gray-400 font-light">25 km</div>
                            <div class="text-gray-400 font-light">150 km</div>
                        </div>
                    </div>

                    <!-- offer -->
                    <div class="rounded-md bg-primary px-6 py-4 space-y-1">
                        <p class="text-white uppercase text-lg">
                            Offre spéciale jusqu’au 15 février
                        </p>
                        <p class="text-tertiary font-bold text-2xl uppercase">
                            5 000 € / pièces* + Frais de notaire offferts**
                        </p>
                    </div>

                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4"
                        :class="displayedComponent === 'all' || displayedComponent === 'list' ? 'lg:block' : 'lg:hidden'"
                    >
                        <template v-if="loading">
                            <ResultSkeletonCard v-for="item in 5" :key="item" />
                        </template>
                        <template v-else>
                            <template v-for="program in programs" :key="program.id">
                                <ResultCard :program="program" />
                            </template>

                            <p v-if="!!programs.length" class="text-center pt-4 text-primary">
                                {{ programs.length }} résultats sur {{ pagination.totalCount }}
                            </p>
                            <div class="col-span-full p-6 flex items-center justify-center">
                                <button
                                    v-if="pagination.currentPage < pagination.maxPage"
                                    type="button"
                                    class="p-5 bg-primary text-white font-extrabold text-base uppercase leading-none rounded-sm inline-flex items-center gap-2"
                                    @click="loadMorePrograms"
                                >
                                    <span>
                                        Plus de résultats
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                                    </svg>
                                </button>
                            </div>
                        </template>
                    </div>
                </div>
                <!-- col-2 -->
                <div
                    class="w-full h-screen bg-white sticky top-0"
                    :class="[
                        !displayMap && 'max-lg:hidden',
                        displayedComponent === 'all' || displayedComponent === 'map' ? 'block' : 'hidden'
                    ]"
                >
                    <!-- Map -->
                    <SearchMap
                        :programs="programs"
                        :loading="loading"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
