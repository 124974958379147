<script setup lang="ts">
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core'

const model = defineModel({
    default: '' as string,
});

const focus = ref(false);
const container = ref(null);
const search = ref('');
const results = ref([
    {key: '100 000 € - 200 000 €', value: '100000-200000'},
    {key: '200 000 € - 300 000 €', value: '200000-300000'},
    {key: '300 000 € - 400 000 €', value: '300000-400000'},
    {key: '400 000 € - 500 000 €', value: '400000-500000'},
    {key: '500 000 € - 600 000 €', value: '500000-600000'},
    {key: '600 000 € - 700 000 €', value: '600000-700000'},
    {key: '700 000 € - 800 000 €', value: '700000-800000'},
    {key: '800 000 € - 900 000 €', value: '800000-900000'},
    {key: '900 000 € - 1 000 000 €', value: '900000-1000000'},
] as SelectOption[]);

const displayLabel = computed(() => {
    return results.value.find(result => result.value === model.value)?.key;
});

const toggleItemOnModel = (result: SelectOption) => {
    model.value = result.value === model.value ? '' : result.value;
}

const computedResults = computed(() => {
    return results.value.filter(result => result.value.includes(search.value));
});

onClickOutside(container, () => {
    focus.value = false;
    search.value = '';
});
</script>

<template>
    <div ref="container" class="mt-3 flex flex-col relative">
        <label
            for="price"
            :class="`uppercase text-dark/60 absolute z-10 ${focus || search || model ? 'text-xs -top-3' : 'text-base top-2.5'} transition-all duration-150 ease-in-out pointer-events-none`"
        >
            Budget
        </label>
        <div v-if="!focus && model" class="w-full absolute py-2 bg-white text-nowrap truncate">
            {{ displayLabel }}
        </div>
        <input
            id="price"
            name="price"
            type="text"
            class="border-b-2 border-secondary py-2 outline-none"
            @focus="focus = true"
            v-model="search"
            autocomplete="off"
        >
        <div v-if="focus" class="w-full absolute top-11 z-60 bg-white shadow max-h-64 overflow-y-auto">
            <ul class="w-full px-4 divide-y divide-secondary">
                <li v-for="(result, index) in computedResults" :key="index" @click="toggleItemOnModel(result)">
                    <div class="p-3 pl-7 relative flex items-center gap-3 cursor-pointer hover:bg-primary/10">
                        <svg v-if="result.value === model" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 text-primary absolute left-0">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                        </svg>
                        {{ result.key }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
