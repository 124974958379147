
document.addEventListener('DOMContentLoaded', () => {
    // DESKTOP
    const menuPanels: NodeListOf<HTMLElement> = document.querySelectorAll('[data-panel-parent-level="0"]');
    const panelWithChildren: NodeListOf<HTMLElement> = document.querySelectorAll('.menu-item-has-children');
    const residencesBlock: HTMLElement | null = document.querySelector('.residences-block');
    
    // MOBILE
    const openMenuBtn: HTMLElement | null = document.querySelector('#openMenuBtn');
    const mobileMenu: HTMLElement | null = document.querySelector('#mobile-menu');

    const desktopMenu = new DesktopMenu(residencesBlock, menuPanels, panelWithChildren);
    desktopMenu.init();

    const mobileMenuInstance = new MobileMenu(openMenuBtn, mobileMenu,);
    mobileMenuInstance.init();
});

class DesktopMenu {
    constructor(private residencesBlock: HTMLElement | null, private menuPanels: NodeListOf<HTMLElement>, private panelWithChildren: NodeListOf<HTMLElement>) {}

    init() {
        if (!this.residencesBlock || !this.menuPanels || !this.panelWithChildren) return;

        // Hide all menu panels initially
        this.menuPanels.forEach((panel) => {
            panel.classList.add('hidden');
        });

        // Show child panels on mouseover
        this.panelWithChildren.forEach((item) => {
            const panel = item.querySelector(`[data-panel-parent-level="0"]`);
            item.addEventListener('mouseover', () => {
                panel?.classList.remove('hidden');
            });

            item.addEventListener('mouseleave', () => {
                panel?.classList.add('hidden');
            });
        });

        // Toggle category lists on click
        this.openCategoryListBtn.forEach(btn => {
            btn.addEventListener('click', () => {
                const list: HTMLElement | null = document.querySelector(`[data-list-items-category="${btn.dataset.categoryId}"]`);

                if (list) {
                    list.classList.toggle('show');
                }
            });
        });
    }

    private get openCategoryListBtn(): NodeListOf<HTMLElement> {
        return document.querySelectorAll('.openCategoryList');
    }
}

class MobileMenu {
    constructor(private openMenuBtn: HTMLElement | null, private mobileMenu: HTMLElement | null) {}
    
    init() {
        const menuCategories: NodeListOf<HTMLElement> = document.querySelectorAll('[data-menu-item-level="0"]');
        // const residencesCategories: NodeListOf<HTMLElement> = document.querySelectorAll('[data-residences-category]');
        const openResidencesCategoriesBtns: NodeListOf<HTMLElement> = document.querySelectorAll('[data-residences-btn]');
        if (!this.openMenuBtn || !this.mobileMenu) return;

        this.openMenuBtn.addEventListener('click', () => {
            this.mobileMenu!.classList.toggle('translate-x-full');
        });

        menuCategories?.forEach(category => {
            category.addEventListener('click', () => {
                category.querySelector('.open-icon')?.classList.toggle('rotate-180');
                category.querySelectorAll('.mobile-menu-subitem')?.forEach(item => {
                    item.classList.toggle('closed');
                });
            })
        })

        openResidencesCategoriesBtns?.forEach(btn => {
            btn.addEventListener('click', (event) => {
                event?.stopPropagation()
                const category: HTMLElement | null = document.querySelector(`[data-residences-category="${btn.dataset.residencesBtn}"]`);

                if (category) {
                    category.classList.toggle('closed');
                }
            });
        })
    }
}