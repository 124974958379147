<script setup>
import { ref, computed, onMounted, watch, nextTick } from "vue";
import axios from "axios";

const props = defineProps({
    programs: {
        type: Array,
        default: [],
    },
    loading: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits([
    "update:programs",
    "update:loading",
]);

const markers = ref([]);
const mapBoxRef = ref('map_box')
const cluster = ref(null);
const map = ref({});
const mapObject = ref(null);
const mapInfoWindow = ref({});
const mapIsReady = ref(false);
const kimono = window.kimono;
onMounted(() => {
    setTimeout(() => {
        try {
            const container = mapBoxRef.value;

            if (!container) {
                console.error('Map container not found');
                return;
            }

            const mapElement = kimono.addMap({
                zoom: 6,
                theta: 55,
                phi: 5,
                lon: 2.333333,
                lat: 48.866667,
                container: container,
            });
            
            mapInfoWindow.value = new f4.map.InfoWindow({
                content: "",
                closeButton: false,
                zIndex: 100,
            });

            mapElement.on("load", function (realEstateAd, map) {
                // console.log('map loaded')
                mapObject.value = map;
                setMarkers(map);
                
            });
        } catch (error) {
            console.error('Error mounting map', error);
        }
    }, 1000);
    // initMap();
});


const computedPrograms = computed({
    get() {
        return props.programs;
    },
    set(programs) {
        emit("update:programs", programs);
    },
});

const computedLoading = computed({
    get() {
        return props.loading;
    },
    set(value) {
        emit("update:loading", value);
    },
});

const setMarkers = (map) => {
    if(map !== null) {
        if (map === null) {
            setTimeout(function () {
                setMarkers();
            }, 1000);
        } else {
            if (cluster.value !== null) {
                cluster.value.clearMarkers();
            }

            markers.value.forEach((marker) => {
                marker.setMap(null)
            });

            markers.value = [];

            computedPrograms.value?.forEach((program) => {
                if (
                    program.latitude !== 0 &&
                    program.longitude !== 0
                ) {
                    let marker = new f4.map.Marker({
                        position: new f4.map.LatLng(
                            parseFloat(program.latitude),
                            parseFloat(program.longitude)
                        ),
                        map: map,
                        clickable: true,
                        zIndex: 80,
                        icon: {
                            url:
                                urls.theme +
                                "/assets/images/placeholder.png",
                        },
                    });

                    marker.addListener("mouseover", async () => {
                            // gtm.gae(
                            //     "Carte des programmes",
                            //     "Survole",
                            //     program.id
                            // );
                        let data = new FormData();
                        data.append("action", "search_infowindow");
                        data.append("id", program.id);
                        await axios
                            .post(urls.ajax, data)
                            .then((response) => {
                                mapInfoWindow.value.setContent(
                                    response.data
                                );
                                mapInfoWindow.value.open(
                                    map,
                                    marker
                                );
                            })
                            .catch((error) => console.error(error));
                    });

                    marker.addListener("click", () => {
                        // gtm.gae(
                        //     "Carte des programmes",
                        //     "Clique",
                        //     program.id
                        // );
                        window.location.href = program.permalink;
                    });

                    marker.addListener("mouseout", () => {
                        mapInfoWindow.value.close();
                    });

                    if(marker) markers.value.push(marker);
                }
            });

            cluster.value = new MarkerClusterer(
                map,
                markers.value,
                {
                    imagePath: urls.theme + "/assets/images/cluster.png",
                    styles: [
                        {
                            url: urls.theme + "/assets/images/cluster.png",
                            height: 24,
                            width: 24,
                            textColor: "#FFFFFF",
                        },
                        {
                            url: urls.theme + "/assets/images/cluster.png",
                            height: 24,
                            width: 24,
                            textColor: "#FFFFFF",
                        },
                        {
                            url: urls.theme + "/assets/images/cluster.png",
                            height: 24,
                            width: 24,
                            textColor: "#FFFFFF",
                        },
                        {
                            url: urls.theme + "/assets/images/cluster.png",
                            height: 24,
                            width: 24,
                            textColor: "#FFFFFF",
                        },
                        {
                            url: urls.theme + "/assets/images/cluster.png",
                            height: 24,
                            width: 24,
                            textColor: "#FFFFFF",
                        },
                    ],
                    minimumClusterSize: 5,
                }
            );
            cluster.value.fitMapToMarkers();
        }
    };

    // mapIsReady.value = true;
}

watch(computedPrograms, (programs) => {
    if(programs.length && mapObject.value) {
        setMarkers();
    }
});
</script>

<template>
    <div
        class="h-full w-full" id="map_box" ref="mapBoxRef"
    ></div>
</template>
