<script setup lang="ts">
import { reactive, ref, watch, onMounted } from 'vue'
import axios from "axios";

// import { storeToRefs } from 'pinia';

import PlacesField from '@/vue/search/PlacesField.vue';
import TypesField from '@/vue/search/TypesField.vue';
import PriceField from '@/vue/search/PriceField.vue';
import RoomsField from '@/vue/search/RoomsField.vue';
import FiscalField from '@/vue/search/FiscalField.vue';
import AvancementField from '@/vue/search/AvancementField.vue';

// import { useSearchFormStore } from '@/stores/searchFormStore';
// const { formPayload } = storeToRefs(useSearchFormStore());

const emits = defineEmits(['handleSubmitSearch', 'displayMoreFilters'])

const query = reactive({
    types: [] as ProgramType[],
    rooms: [] as ProgramRoom[],
    price: '' as ProgramPrice,
    dispositifs: [] as ProgramDispositif[],
    status: [] as ProgramStatus[],
    department: '' as string,
    city: '' as string,
    places: [] as ProgramPlace[],
});

const displayHiddenFilters = ref(false);
const programsCount = ref(0);

const handleDisplayFilters = () => {
    displayHiddenFilters.value = !displayHiddenFilters.value;
}

const formatUrlParameters = () => {
    const params = new URLSearchParams();

    (Object.keys(query) as Array<keyof typeof query>).forEach(key => {
        const value = query[key];
        if (Array.isArray(value)) {
            value.forEach((item, index) => {
                if (key === 'places') {
                    params.append(`${String(key)}[${index}]`, String(item?.value));
                } else {
                    params.append(`${String(key)}[${index}]`, String(item));
                }
            });
        }
    });
    
    console.log('url', params.toString())

    return params.toString();
}

const loadPrograms = async () => {
    const response = await getProgramsCount();
    programsCount.value = response.programs_count;
}

const getProgramsCount = async () => {
    const response = await axios
        .get(`${import.meta.env.VITE_APP_URL}/wp-admin/admin-ajax.php?action=search_programs_count&${formatUrlParameters()}`);

    return response.data;
};

const getPrograms = async () => {
    window.location.href = `/immobilier-neuf/?${formatUrlParameters()}`;
};

watch(query, () => {
    getProgramsCount().then((response) => {
        programsCount.value = response.programs_count;
    });
}, { deep: true });

onMounted(() => {
    loadPrograms();
})
</script>

<template>
    <div class="absolute bottom-12 w-[90dvw] z-100 left-1/2 -translate-x-1/2">

        <form @submit.prevent="getPrograms" class="max-lg:hidden grid grid-cols-4 items-end gap-x-4 gap-y-6 bg-white p-6 rounded-md">
            <PlacesField
                v-model="query.places"
            />
            <TypesField
                v-model="query.types"
                />
            <PriceField
                v-model="query.price"
            />

            <div class="flex">
                <button
                    type="submit"
                    class="p-5 bg-primary text-white grow font-extrabold text-base uppercase leading-none rounded-sm inline-flex items-center gap-2 justify-center"
                    @submit.prevent="getPrograms"
                >
                    <span class="whitespace-nowrap">
                        Voir les {{ programsCount ?? 0 }} résultat{{ programsCount > 1 ? 's' : '' }}
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
                </button>
            </div>

            <div v-if="displayHiddenFilters" class="col-span-4 grid grid-cols-4 gap-4">
                <RoomsField
                    v-model="query.rooms"
                />

                <FiscalField
                    v-model="query.dispositifs"
                />

                <AvancementField
                    v-model="query.status"
                />
            </div>
        </form>

        <button
            @click="handleDisplayFilters"
            type="button"
            class="max-lg:hidden mt-4 left-[5dvw] p-2 bg-red text-white font-regular text-base uppercase leading-none rounded-md inline-flex items-center gap-2 z-100"
        >
            <span class="btnContent">
                {{ displayHiddenFilters ? 'Moins de filtres' : 'Recherche avancée' }}
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
            </svg>
        </button>
</div>

</template>
