<script setup lang="ts">
import { ref } from 'vue';
import searchFormHomeMobile from '@/vue/forms/SearchFormHomeMobile.vue';
import searchFormHomeDesktop from '@/vue/forms/SearchFormHomeDesktop.vue';
import { storeToRefs } from 'pinia';

import { useSearchFormStore } from '../stores/searchFormStore';
const { formPayload } = storeToRefs(useSearchFormStore());

const formIsOpen = ref<Boolean>(false)
const allFiltersShown = ref<Boolean>(false)

// METHODS
const handleSubmitSearch = () => {
    console.log('submit form', formPayload.value)
}

const handleDisplaySearchForm = () => {
    formIsOpen.value = !formIsOpen.value
}

const displayMoreFilters = () => {
    allFiltersShown.value = !allFiltersShown.value
}
</script>

<template>
    <button
        @click="handleDisplaySearchForm"
        class="absolute w-max bg-white rounded-full flex items-center gap-2 p-2 z-50 left-1/2 -translate-x-1/2 bottom-8 lg:hidden"
        role="button"
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <circle cx="20" cy="20" r="20" transform="rotate(-90 20 20)" fill="#640A32"/>
            <path d="M18.7677 25.5354C22.9472 25.5354 26.3354 22.1472 26.3354 17.9676C26.3354 13.7881 22.9472 10.3999 18.7677 10.3999C14.5881 10.3999 11.2 13.7881 11.2 17.9676C11.2 22.1472 14.5881 25.5354 18.7677 25.5354Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M29.0376 30.4002L23.551 24.9136" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="text-primary">Trouvez votre logement</p>
    </button>

    <searchFormHomeMobile
        :formIsOpen="formIsOpen"
        @handleDisplaySearchForm="handleDisplaySearchForm"
    />

    <searchFormHomeDesktop
        @handleSubmitSearch="handleSubmitSearch"
        @displayMoreFilters="displayMoreFilters"
    />
</template>
