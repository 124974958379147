import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useSearchFormStore = defineStore('searchForm', () => {
    // STATE
    const pricesList = [
        "Non précisé",
        "Moins de 100 000€",
        "Entre 100 000€ et 200 000€",
        "Entre 200 000€ et 300 000€",
        "Entre 300 000€ et 400 000€",
        "Entre 400 000€ et 500 000€",
        "Entre 500 000€ et 600 000€",
        "Entre 600 000€ et 700 000€",
        "Entre 700 000€ et 800 000€",
        "Entre 800 000€ et 900 000€",
        "Plus de 900 000€",
    ]
    
    const typesList = [
        "Appartement",
        "Maison",
        "Terrain",
        "Parking",
        "Local commercial",
        "Bureau",
        "Immeuble",
        "Fonds de commerce",
        "Autre",
    ] 
    
    const roomsList = [
        "Studio",
        "2 pièces",
        "3 pièces",
        "4 pièces",
        "5 pièces et +",
    ]
    
    const dispositifsList = [
        "PTZ",
        "PINEL",
        "PINEL+",
        "TVA réduite",
        "Prix maitrisés",
        "Nue-propriété",
    ]

    const avancementList = [
        'Avant-première',
        'En commercialisation',
        'Travaux en cours',
        'Livraison immédiate',
    ]

    const formPayload = ref({
        localisation: [],
        typesList: [],
        prices: [],
        roomsList: [],
        dispositif: [],
        avancement: []
    })

    // METHODS
    const resetFormPayload = () => {
        formPayload.value = {
            localisation: [],
            typesList: [],
            prices: [],
            roomsList: [],
            dispositif: [],
            avancement: []
        }
    }
    
    return {
        pricesList,
        typesList,
        roomsList,
        dispositifsList,
        avancementList,
        formPayload,
        resetFormPayload
    };
})