import axios from 'axios'

document.addEventListener("DOMContentLoaded", function () {
    const form = document.querySelector(".rappel-form");
    const submitButton = document.querySelector(".submitRappelForm");
    const dialog = document.querySelector('#rappelContactFormDialog');
    console.log(form, submitButton);

    document.querySelectorAll('.openRappelForm')?.forEach(item => {
        item?.addEventListener('click', () => {
            dialog?.classList.toggle('hidden');
        })
    });

    document.querySelector('#closeRappelForm')?.addEventListener('click', () => {
        dialog?.classList.add('hidden');
    });

    form?.addEventListener('submit', (event) => {
        console.log('submit contact form')
        event.preventDefault();

        const formData = new FormData(event.target as HTMLFormElement);
        submitForm(formData);
    })

    const toggleButtonSubmit = (isDisabled: boolean) => {
        submitButton?.setAttribute('disabled', isDisabled ? 'disabled' : '');
        submitButton?.classList.add(isDisabled ? 'cursor-not-allowed': 'cursor-pointer');
        submitButton?.classList.add(isDisabled ? 'opacity-50' : 'opacity-100');
    }

    const displayMessage = (type: 'success' | 'error', message: string) => {
        const messageContainer: HTMLElement | null = document.querySelector('.rappelFormMessage');

        if(!messageContainer) return;

        messageContainer.classList.remove('hidden');
        messageContainer.classList.add(type === 'success' ? 'bg-green' : 'bg-red');
        messageContainer.innerText = message;

        setTimeout(() => {
            messageContainer.classList.add('hidden');
        }, 5000);
    }

    const submitForm = async (formData: FormData) => {
        try {
            toggleButtonSubmit(true);
            // @ts-ignore
            console.log('submitting form', formData, 'to url', urls.ajax);
            // @ts-ignore
            const response = await axios.post(urls.ajax, formData);

            if(response.status !== 200) throw new Error('Failed to submit form');

            console.log(response);
            displayMessage('success', 'Votre message a bien été envoyé');

        } catch (error) {
            console.error(error);
        } finally {
            toggleButtonSubmit(false);
        }
    }
});
