class ResidencesCategoriesHomepage {
    private container: HTMLElement | null

    constructor() {
        this.container = document.querySelector('.homepageResidencesCategories');

        this.displayCategoryPrograms()
    }

    private displayCategoryPrograms(): void {
            let currentCategory: string | undefined = 'lancement'
        
            if(!!this.container) {
                const categories: NodeListOf<HTMLElement> = this.container.querySelectorAll('[data-residence-category]');
                const programsCards: NodeListOf<HTMLElement> = document.querySelectorAll('[data-program-status]');
        
                categories.forEach((category: HTMLElement) => {            
                    category.addEventListener('click', function () {
                        currentCategory = category.dataset.residenceCategory
                        
                        const filterResidencesBtn = document.querySelector('.filterResidences')
                        if(filterResidencesBtn) {
                            filterResidencesBtn.setAttribute('href', `/immobilier-neuf/?status=${currentCategory}`)
                        }
                        

                        categories.forEach((category: HTMLElement) => {
                            category.classList.remove('active');
                        });
        
                        programsCards.forEach((card: HTMLElement) => {
                            if(card.dataset.programStatus !== currentCategory) {
                                card.classList.add('hidden');
                            } else {
                                card.classList.remove('hidden');
                            }
                        });
        
                        category.classList.add('active');
                    });
                });
            }
    }
    public static init(): void {
        document.addEventListener("DOMContentLoaded", function () {
            new ResidencesCategoriesHomepage();
        });
    }
}

ResidencesCategoriesHomepage.init();
