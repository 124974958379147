if (typeof Object.assign != "function") {
    Object.assign = function (target, varArgs) {
        // .length of function is 2
        "use strict";
        if (target == null) {
            // TypeError if undefined or null
            throw new TypeError("Cannot convert undefined or null to object");
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
            var nextSource = arguments[index];

            if (nextSource != null) {
                // Skip over if undefined or null
                for (var nextKey in nextSource) {
                    // Avoid bugs when hasOwnProperty is shadowed
                    if (
                        Object.prototype.hasOwnProperty.call(
                            nextSource,
                            nextKey,
                        )
                    ) {
                        to[nextKey] = nextSource[nextKey];
                    }
                }
            }
        }
        return to;
    };
}

// module.exports = {
//     gtmEvent: function (event, data) {
//         var push_data = { event: event };
//         if (data) {
//             push_data = Object.assign(push_data, data);
//         }
//         dataLayer.push(push_data);
//     },
//     gae: function (category, action, label, other) {
//         var push_data = { event: "GAE" };
//         if (typeof category != "undefined") {
//             push_data.category = category;
//         }
//         if (typeof action != "undefined") {
//             push_data.action = action;
//         }
//         if (typeof label != "undefined") {
//             push_data.label = label;
//         }
//         if (typeof other != "undefined") {
//             push_data = Object.assign(push_data, other);
//         }
//         dataLayer.push(push_data);
//     },
// };


export const gtmEvent = function (event, data) {
    var push_data = { event: event };
    if (data) {
        push_data = Object.assign(push_data, data);
    }
    dataLayer.push(push_data);
};

export const gae = function (category, action, label, other) {
    var push_data = { event: "GAE" };
    if (typeof category != "undefined") {
        push_data.category = category;
    }
    if (typeof action != "undefined") {
        push_data.action = action;
    }
    if (typeof label != "undefined") {
        push_data.label = label;
    }
    if (typeof other != "undefined") {
        push_data = Object.assign(push_data, other);
    }
    dataLayer.push(push_data);
};