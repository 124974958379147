document.addEventListener("DOMContentLoaded", function () {
    const faqBtns: NodeListOf<HTMLElement> = document.querySelectorAll(
        ".displayFaqQuestionBtn",
    );
    faqBtns.forEach((btn: HTMLElement) => {
        btn.addEventListener("click", function () {
            btn.nextElementSibling?.classList.toggle("hidden");
        });
    });
});
