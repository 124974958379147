// OLD JS FILES IMPORT
// import "../scripts/includes/programs";
// import "../scripts/includes/program";
import "../scripts/includes/helpers";
import "../scripts/includes/menu";
import "../scripts/includes/panel";
import "../scripts/includes/return_top";
// import "../scripts/includes/shortcut";
import "../scripts/includes/page";
// import "../scripts/includes/collapse";
import "../scripts/includes/contactform";

import "@/components/offers-slider.ts";
// import "@/components/image-slider.ts";
import "@/components/faq-block.ts";
import "@/components/program-card.ts";
import "@/components/contact-form-block.ts";
import "@/components/rappel-form-block.ts";
import "@/components/menu-topbar.ts";
import "@/components/immodvisors.ts";

// HOMEPAGE
import "@/components/homepage/hero-slider.ts";
import "@/components/homepage/residences-categories.ts";
import "@/components/homepage/header-search-form.ts";

// PROGRAM PAGE
import "@/components/program/program-contact-form.ts";
import "@/components/program/avancement.ts";
import "@/components/program/map-program.ts";
import "@/components/program/menu-program.ts";

// VUE COMPONENTS
import SearchFormHome from "@/vue/SearchFormHome.vue";
import SearchResults from "@/vue/SearchResults.vue";
import ParrainageForm from "@/vue/ParrainageForm.vue";
import FAQ from "../scripts/components/Faq.vue";
import { createApp } from "vue";
import { createPinia } from "pinia";

// TEST JQUERY
// @ts-ignore
import $ from "jquery";
import _ from "lodash";

// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

const pinia = createPinia();

createApp(SearchFormHome)
    .use(pinia)
    .mount("#search-form-home");

createApp(SearchResults)
    .use(pinia)
    .mount("#search-results");

createApp(ParrainageForm)
    .use(pinia)
    .mount("#parrainage-form");

createApp(FAQ)
    .use(pinia)
    .mount("#faq-section");

// const getImmo = async () => {
//     const response = await fetch('https://api-reviews.immodvisor.com/api/company/richs_snippets?id=29567&key=L33DQDSVAR7ML95SSXFZPSPWP6QQU3CH5FRPB69M')
//                         .then(response => {
//                             console.log('response', response);
//                             response.text()
//                         })
// }

// getImmo()
